import Logo from "../../img/ladc-logo.png"
import "./index.scss"

function Header() {
  return (
    <header>
      <a href="https://ladesignconcepts.com" target="_blank" rel="noreferrer">
        <img src={Logo} alt="L.A. Design Concepts - Since 1987" width={1756} height={1000} />
      </a>
    </header>
  )
}

export default Header
