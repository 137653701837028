import * as React from "react"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
// import AddressAutocomplete from "../AddressAutocomplete"
import Modal from "../Modal"
import Confirm from "../Confirm"
import "./index.scss"

function createSampleTemplate() {
  return {
    id: uuidv4(),
    brand: "",
    patternName: "",
    patternNumber: "",
    color: "",
    type: "",
  }
}

const required = true

function Form() {
  const [orderDate, setOrderDate] = React.useState(null)
  const [orderId, setOrderId] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [samples, setSamples] = React.useState([createSampleTemplate()])
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    setShowConfirm(true)
  }

  const handleConfirm = async () => {
    setShowConfirm(false)

    const res = await axios({
      url:
        process.env.NODE_ENV === "production"
          ? "https://support.ladesignconcepts.com/sample-re-order-form"
          : "http://localhost:8000/sample-re-order-form",
      method: "POST",
      data: {
        orderDate,
        orderId,
        email,
        firstName,
        lastName,
        samples,
      },
    })

    if (res.data?.success) {
      setShowSuccess(true)
      setOrderDate(null)
      setOrderId("")
      setEmail("")
      setFirstName("")
      setLastName("")
      setSamples([createSampleTemplate()])
    } else {
      // something went wrong
    }
  }

  const handleSampleChange = (e, i, field) => {
    let clone = samples.slice()
    clone[i][field] = e.target.value
    setSamples(clone)
  }

  const addSample = e => {
    let clone = samples.slice()
    clone.push(createSampleTemplate())
    setSamples(clone)
    window.scrollTo(0, document.body.scrollHeight)
  }

  const removeSample = (e, i) => {
    let clone = samples.slice().filter((_, index) => i !== index)
    setSamples(clone)
  }

  return (
    <>
      <form id="form" onSubmit={handleSubmit}>
        <div className="order-info">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Order Date"
              value={orderDate}
              onChange={newValue => {
                setOrderDate(newValue)
              }}
              renderInput={params => <TextField required={required} fullWidth {...params} />}
            />
          </LocalizationProvider>
          <TextField
            required={required}
            fullWidth
            id="order-number"
            type="number"
            label="Order Number"
            value={orderId}
            onChange={e => setOrderId(e.target.value)}
          />
          <TextField
            required={required}
            fullWidth
            id="email"
            type="email"
            label="Email Address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <div className="full-name">
            <TextField
              required={required}
              fullWidth
              id="first-name"
              type="text"
              label="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <TextField
              required={required}
              fullWidth
              id="last-name"
              type="text"
              label="Last Name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          {/* <AddressAutocomplete /> */}
        </div>
        <div id="samples">
          <p className="label">Samples</p>
          {samples.map((sample, i) => (
            <div key={sample.id} className="sample">
              <div className="fields">
                <TextField
                  autoComplete={"brand-" + sample.id}
                  required={required}
                  id={`brand-${i}`}
                  type="text"
                  label="Brand"
                  size="small"
                  value={sample.brand}
                  onChange={e => handleSampleChange(e, i, "brand")}
                />
                <TextField
                  autoComplete={"name-" + sample.id}
                  id={`pattern-name-${i}`}
                  type="text"
                  label="Pattern Name"
                  size="small"
                  value={sample.patternName}
                  onChange={e => handleSampleChange(e, i, "patternName")}
                />
                <TextField
                  autoComplete={"number-" + sample.id}
                  id={`pattern-number-${i}`}
                  type="text"
                  label="Pattern Number"
                  size="small"
                  value={sample.patternNumber}
                  onChange={e => handleSampleChange(e, i, "patternNumber")}
                />
                <TextField
                  autoComplete={"color-" + sample.id}
                  required={required}
                  id={`color-${i}`}
                  type="text"
                  label="Color"
                  size="small"
                  value={sample.color}
                  onChange={e => handleSampleChange(e, i, "color")}
                />
                <FormControl required={required} className="type-select" size="small">
                  <InputLabel id={`type-label-${i}`}>Type</InputLabel>
                  <Select
                    labelId={`type-label-${i}`}
                    id={`type-${i}`}
                    value={sample.type}
                    label="Type"
                    onChange={e => handleSampleChange(e, i, "type")}
                  >
                    <MenuItem value="fabric">Fabric</MenuItem>
                    <MenuItem value="wallpaper">Wallpaper</MenuItem>
                    <MenuItem value="trim">Trim</MenuItem>
                  </Select>
                </FormControl>
                {i === 0 && <div className="fake-cancel" />}
                {i !== 0 && (
                  <IconButton
                    disableRipple
                    onClick={e => removeSample(e, i)}
                    sx={{
                      transition: "all .2s ease",
                      "&:hover": { color: "red" },
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="actions">
          <Button
            id="add-sample"
            className="button"
            type="button"
            variant="contained"
            onClick={addSample}
          >
            <AddIcon sx={{ mr: 0.5 }} /> Add Sample
          </Button>
          <Button id="submit" className="button" type="submit" variant="contained">
            Submit
          </Button>
        </div>
      </form>
      <Modal open={showSuccess} onClose={() => setShowSuccess(false)} />
      <Confirm open={showConfirm} onConfirm={handleConfirm} onClose={() => setShowConfirm(false)} />
    </>
  )
}

export default Form
