import Box from "@mui/material/Box"
import Header from "./components/Header"
import Title from "./components/Title"
import Form from "./components/Form"

function App() {
  return (
    <Box sx={{ paddingBottom: "100px" }}>
      <Header />
      <Title />
      <Form />
    </Box>
  )
}

export default App
