import "./index.scss"

function Title() {
  return (
    <div id="title">
      <h1>Sample Re-Order</h1>
      <p>
        Please request re-orders (ONLY) if you have not received your sample after 15 business days.
      </p>
      <p>
        Due to the high volume of samples being requested, we kindly ask you to fill out the form
        below as accurately as possible to help us expedite these samples to you.
      </p>
    </div>
  )
}

export default Title
